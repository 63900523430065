<script setup lang="ts">
import { onLaunch, onShow, onHide } from '@dcloudio/uni-app'
// import * as Sentry from '@sentry/vue'

onLaunch(() => {
  console.log('App Launch')

  // if (process.env.NODE_ENV === 'production') {
  //   Sentry.init({
  //     dsn: process.env.VITE_SENTRY_DSN,
  //     integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  //     tracesSampleRate: 1.0,
  //     // This sets the sample rate at 10%. You may want to change it to 100%
  //     // while in development and then sample at a lower rate in production.
  //     replaysSessionSampleRate: 0.1,
  //     // If you're not already sampling the entire session, change the sample rate to 100%
  //     // when sampling sessions where errors occur.
  //     replaysOnErrorSampleRate: 1.0
  //   })
  // }
})
onShow(() => {
  console.log('App Show')
})
onHide(() => {
  console.log('App Hide')
})
</script>
<style lang="scss">
@import 'vk-uview-ui/index.scss';
</style>

import { createSSRApp } from 'vue'
import * as Pinia from 'pinia'
// @ts-ignore
import uView from 'vk-uview-ui'
// import * as Sentry from '@sentry/vue'
import piniaPersist from 'pinia-plugin-persist-uni'
import App from './App.vue'

// unocss
import 'uno.css'
import '@/styles/index.scss'

export function createApp() {
  const app = createSSRApp(App)

  const pinia = Pinia.createPinia()
  pinia.use(piniaPersist)

  app.use(pinia)
  app.use(uView)

  // sentry
  // if (process.env.NODE_ENV === 'production') {
  // Sentry.init({
  // app, // vue app
  // dsn: import.meta.env.VITE_SENTRY_DSN,
  // integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // tracesSampleRate: 1.0
  // })
  // }

  uni.addInterceptor('checkLogin', {
    invoke(args) {
      // request 触发前拼接 url
      console.log(args)
    },
    success(args) {
      // 请求成功后，修改code值为1
      console.log(args)
    },
    fail(err) {
      console.log('interceptor-fail', err)
    },
    complete(res) {
      console.log('interceptor-complete', res)
    }
  })

  return {
    app,
    // uni-app 官方文档示例 https://zh.uniapp.dcloud.io/tutorial/vue3-pinia.html#%E7%8A%B6%E6%80%81%E7%AE%A1%E7%90%86-pinia
    Pinia // 此处必须将 Pinia 返回
  }
}
